import React from "react"
import { I18nContext } from "./src/context/I18nContext"
import "./src/scss/style.scss"

const onRedirectCallback = (appState) => {
  if (
    window &&
    window.location &&
    window.location.href &&
    appState?.targetUrl
  ) {
    window.location.href = appState?.targetUrl
  }
}

const wrapPageElement = ({ element, props }) => {
  return <I18nContext pageContext={props.pageContext}>{element}</I18nContext>
}

export { wrapPageElement }
