module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/@yumgmbh/gatsby-theme-yum-components/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@yumgmbh/gatsby-plugin-sb-nav/gatsby-browser.js'),
      options: {"plugins":[],"storyblokToken":"Jk5HG2HHXfoRIYIt5G4ExQtt","version":"published","by_slugs":"global/navigations/*"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#353535","theme_color":"#353535","display":"minimal-ui","icon":"src/images/ruko-logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"90d64ffeb2824c3bd0b566b49ec54de8"},
    },{
      plugin: require('../plugins/contentserv-source-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://ruko.de","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
